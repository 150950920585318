@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Calibre";
    font-weight: 400, 500, , 600, 700;
    src: url("./fonts/Calibre/Calibre-Regular.woff2") format("woff2"),
      url("./fonts/Calibre/Calibre-Regular.woff") format("woff");
    font-display: swap;
  }
  @font-face {
    font-family: "SFMono";
    font-weight: 400, 500, , 600, 700;
    src: url("./fonts/SFMono/SFMono-Regular.woff2") format("woff2"),
      url("./fonts/SFMono/SFMono-Regular.woff") format("woff");
    font-display: swap;
  }
}

.header-active {
  position: fixed;
  top: -70px;
  left: 0;
  right: 0;
  transform: translateY(70px);
  transition: transform 500ms ease, background 500ms ease;
}

section {
  margin: 0 auto;
  max-width: 1000px;
}

html {
  scroll-behavior: smooth;
  scrollbar-width: thin;
  scrollbar-color: #a8b2d1;
  background-color: #0a192f;
}

* {
  -webkit-tap-highlight-color: transparent;
}

body {
  color: #a8b2d1;
}
body::-webkit-scrollbar {
  width: 12px;
  height: 20px;
}
body::-webkit-scrollbar-track {
  background: #0a192f;
}
body::-webkit-scrollbar-thumb {
  background-color: #8892b0;
  border: 3px solid #0a192f;
  border-radius: 10px;
}
